import React, { useState } from "react"
import {
  InfoWindow,
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
import MarkerImage from "../../images/marker.svg"
const containerStyle = {
  width: "100%",
  height: "400px",
}

const center = {
  lat: 21.7679,
  lng: 78.8718,
}

function DirectionMap() {
  const [isOpen, setOpen] = useState(false)
  return (
    <MapWithMarker
      isOpen={isOpen}
      setOpen={setOpen}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAP_API}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `400px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  )
}

export default React.memo(DirectionMap)
export const MapWithMarker = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: 39.424982, lng: -123.8028999 }}
    >
      <Marker
        icon={MarkerImage}
        onClick={() => props.setOpen(true)}
        position={{ lat: 39.424982, lng: -123.8028999 }}
      >
        {props.isOpen && (
          <InfoWindow onCloseClick={() => props.setOpen(false)}>
            <div className="map__info__window">
              <p>
                <b> Noyo Harbor Inn</b>
                <br />
                500 Casa Del Noyo
                <br /> 95437
                <br /> Fort Bragg <br />
                United States
              </p>
              <p>
                Coordinates:
                <br /> Lat: 39.424982,
                <br /> Lon: -123.8028999
              </p>
            </div>
          </InfoWindow>
        )}
      </Marker>
    </GoogleMap>
  ))
)

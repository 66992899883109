import { useEffect, useState } from "react"
import useWindowSize from "./useWindowSize"

export default function useBoxSize(props) {
  const { width } = useWindowSize()
  const [swidth, setSWidth] = useState("auto")
  const getListSize = () => {
    const newWidth = props.boxRef.current.clientWidth
    setSWidth(newWidth)
  }
  useEffect(() => {
    getListSize()
  }, [width])
  useEffect(() => {
    window.addEventListener("resize", getListSize)
  }, [])
  return swidth
}

import React, { useRef } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import DirectionMap from "../../components/Map"
import useBoxSize from "../../../hooks/useBoxSize"
import Seo from "../../components/Seo"
import styled from "styled-components"
import useWindowSize from "../../../hooks/useWindowSize"
import { GatsbyImage } from "gatsby-plugin-image"
import { PortableText } from "@portabletext/react"

const HotelDirection = ({ data }) => {
  const {
    title,
    _rawDescription,
    heroImg,
    directionLink,
    drivingTitle,
    drivingSteps,
    propertyMapTitle,
    propertyMapFile,
    propertyMapImage,
    propertyButtonTitle,
  } = data?.sanityPages?.directionsPage
  const boxRef = useRef()
  const swidth = useBoxSize({ boxRef: boxRef })
  const { width } = useWindowSize()
  return (
    <>
      <Seo
        title={"Hotel direction Page"}
        description={
          "To know, how to reach to the hotel by google map or area map."
        }
      />
      <Layout>
        <div className="hotel__direction__wrapper">
          <div className="container">
            <div className="direc__gmap">
              <div className="direction__heading">
                <h3>{title}</h3>
                <div className="container" style={{ margin: "40px 40px" }}>
                  <div className="row">
                    <div className={`${width < 700 ? "col-12" : "col-6"}`}>
                      <div
                        className="luxury_content_blk"
                        style={{
                          aspectRatio: "0.6666666666666666px",
                        }}
                      >
                        <PortableText value={_rawDescription} />
                      </div>
                    </div>
                    <div
                      className={`justify-content-center d-flex flex ${
                        width < 700 ? "col-12" : "col-6"
                      }`}
                    >
                      <img
                        src={heroImg?.asset?.url}
                        style={{ maxHeight: "700px", borderRadius: "10px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="direction__map">
                <DirectionMap />
              </div>
              <div className="get__direct__button">
                <a
                  href={directionLink?.path}
                  className="get__direct"
                  target="_blank"
                >
                  <div>{directionLink?.label}</div>
                </a>
              </div>
            </div>
            <div className="drive__direc__wrap">
              <div className="driving__direct">
                <h3>{drivingTitle}</h3>
                <div className="direction__list">
                  <ul>
                    {drivingSteps.map((item, index) => (
                      <li key={index}>
                        <a href="#">{item}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="property__map__wrap">
            <div className="container">
              <h3>{propertyMapTitle}</h3>
              <div className="property__dowld">
                <a
                  href={propertyMapFile?.asset?.url}
                  className="download__property__map"
                  target={"_blank"}
                >
                  <div>{propertyButtonTitle}</div>
                </a>
              </div>
              <div className="property__map__image" ref={boxRef}>
                <ImageWrapper
                  width={swidth}
                  image={propertyMapImage?.asset?.gatsbyImageData}
                  alt="property map image"
                  className={"property__map__bg"}
                ></ImageWrapper>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default HotelDirection
const ImageWrapper = styled(GatsbyImage)`
  width: ${props => props.width}px;
  height: ${props => 0.6 * props.width}px;
  margin-top: 20px;
`
const HeaderImageWrapper = styled.div`
  background: ${props => `url(${props.bannerImg})`};
  background-size: contain;
  width: ${props => props.width}px;
  height: ${props => 0.75 * props.width}px;
  background-repeat: no-repeat;
  margin-top: 20px;
`

export const Direction = graphql`
  query ($id: String!) {
    sanityPages(id: { eq: $id }) {
      title
      template
      handle {
        current
      }
      directionsPage {
        title
        _rawDescription
        heroImg {
          asset {
            url
          }
        }
        directionLink {
          label
          path
          type
        }
        drivingTitle
        drivingSteps
        propertyMapTitle
        propertyMapFile {
          asset {
            url
          }
        }
        propertyMapImage {
          asset {
            gatsbyImageData(fit: FILLMAX, formats: WEBP)
          }
        }
        propertyButtonTitle
      }
    }
  }
`
